import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Image from '../elements/Image';
import SectionHeader from './partials/SectionHeader';


const Member = (props) => {
  return (<div className="team-member">
    <Image className="team-member-image" src={require("./../../assets/images/avatar.jpg")} alt="{props.name} {props.surname}" />
    <h6>{props.name} {props.surname}</h6>
    <p><a href={`mailto:${props.email}`}>{props.email}</a></p>
    <p>{props.desc}</p>
  </div>)
}


const Team = (props) => {
  const sectionHeader = {
    title: "Our team:",
    paragraph: ""
  }

  const outerClasses = classNames(
    'features-split section',
    'has-top-divider',
    'has-bottom-divider',
    'pt-24',
  );

  return (
      <section className={outerClasses}>
        <div id="team" className="container">
          <SectionHeader data={sectionHeader} className="center-content" />
            <div className="contact-us-outer">
              <div className="cta-slogan contact-us-inner team-outer">
                <Member name="Gal" surname="Gantar" email="gal@505.solutions" desc="Currently studying Mathematics and Computer Science in Ljubljana" />
                <Member name="Janez" surname="Koprivec" email="janez@505.solutions" desc="Currently studying Mathematics and Computer Science in Ljubljana" />
                <Member name="Luka" surname="Skeledzija" email="luka@505.solutions" desc="Currently studying Physics in Ljubljana" />
              </div>
            </div>
        </div>
      </section>)
}


export default Team;
