import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Team from "../components/sections/Team"
import ContactUs from "../components/sections/ContactUs"
import LogoBanner from "../components/sections/LogoBanner"

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      {/* TESTIMONIALI ZAENKRAT VN <Testimonial topDivider />*/}
      <LogoBanner />
      <Team />

      <ContactUs />
      {/*<Cta split />*/}
    </>
  );
}

export default Home;
