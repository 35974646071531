import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';


const Logo = (props) => {
  const location = require(`./../../assets/images/${props.fileName}`)
  return <div className="logo-image-outer">
    <img className="logo-image" src={location} alt={props.desc}/>
  </div>
}

const LogoBanner  = (props) => {
  return <section>
  <div className="container">
    <div className="logo-banner-outer">
      <div className="logo-banner-inner">
        <Logo fileName="aws-logo.svg" desc="Aws logo"/>
        <Logo fileName="google_cloud.svg" desc="Aws logo"/>
        <Logo fileName="azure.svg" desc="Aws logo"/>
      </div>
    </div>
  </div>
</section>
}

export default LogoBanner
