import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';



const ContactUs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  return (
    <section
    {...props}
    className={outerClasses}
  >
    <div className="container">
      <div className="cta-inner contact-us-outer">
        <div className="cta-slogan contact-us-inner">
          <h3 className="m-0 mb-24">Interested in working with us?</h3>
          <a className="contact-us-button" href="mailto:info@505.solutions">Contact us</a>
        </div>
      </div>
    </div>
  </section>)
}

export default ContactUs;
