import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Previous work:',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div id="previous_work" className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  3D graphics
                  </div>
                <h3 className="mt-0 mb-12">
                  Powerpit
                  </h3>
                <p className="m-0">
                  Powerpit is graphics rendering engine, written in C++. Its features include batch rendering, dynamic shader switching, importing 3D models in .obj format, custum library for vector transformations, fully implemented Phong lightning model and much more.
                  </p>
                <a className="button button-primary mt-12" href="https://github.com/galgantar/powerpit" target="_blank">Learn more</a>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/powerpit_engine.png')}
                  alt="Powerpit graphics engine"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Web design
                  </div>
                <h3 className="mt-0 mb-12">
                  Architectural office OFIS website
                  </h3>
                <p className="m-0">
                  Moderen website optimized for all devices, featuring dynamic and responsive desing.
                </p>
                <a className="button button-primary mt-12" href="https://ofis.si/eng/home.html" target="_blank">Learn more</a>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/offis_website.png')}
                  alt="Architectural office OFIS website"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Web developement
                  </div>
                <h3 className="mt-0 mb-12">
                  Alexander Bürkle website
                  </h3>
                <p className="m-0">
                  Implementing React components into modern websites.
                  </p>
                <a className="button button-primary mt-12" href="https://alexander-buerkle.com/de-de" target="_blank">Learn more</a>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/alexander.png')}
                  alt="Alexander Bürkle website"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Web developement
                  </div>
                <h3 className="mt-0 mb-12">
                  Green design guru website
                  </h3>
                <p className="m-0">
                  Responsive, modernly designed website.
                  </p>
                <a className="button button-primary mt-12" href="https://www.greendesignguru.com/#/" target="_blank">Learn more</a>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/green-design.png')}
                  alt="Green design guru website"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
